var opinionsSlider;

const breakpoint = window.matchMedia('(max-width:768px');


const breakpointChecker = function() {
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true || width < 768) {

        // clean up old instances and inline styles when available
        if (opinionsSlider !== undefined) opinionsSlider.destroy(true, true);

        // or/and do nothing
        return;

        // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {

        // fire small viewport version of swiper
        return enableSwiper();

    }

};


const enableSwiper = function() {
    if (!$(".js-slider-opinions").length) {
        return;
    }
    opinionsSlider = new Swiper(".js-slider-opinions", {
        // Optional parameters
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        grabCursor: true,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
                centeredSlides: true,
                watchSlidesVisibility: true,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: true,
                watchSlidesVisibility: true,
            },
        },
    });
}


// keep an eye on viewport size changes
breakpoint.addListener(breakpointChecker);

// kickstart
breakpointChecker();