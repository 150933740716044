class PostsListSlider {
    constructor() {

        this.settings = {
            slidesPerView: 1,
            noSwiping: true,
            noSwipingClass: 'swiper-slide',

            breakpoints: {
                576: {
                    // Optional parameters
                    navigation: {
                        nextEl: ".c-user-bookmarks-slider__nav-next",
                        prevEl: ".c-user-bookmarks-slider__nav-prev",
                    },
                    scrollbar: {
                        el: ".swiper-scrollbar",
                        hide: true,
                    },

                    grabCursor: true,
                    noSwiping: false,
                    slidesPerView: 2.3,
                    spaceBetween: 20,
                    watchSlidesVisibility: true,
                },
            },
        }
        this.events();
    }

    // Events
    events() {
        this.start();
    }

    start() {
        new Swiper(".c-posts-list-slider", this.settings);
    }
}

const postsListSlider = new PostsListSlider();