function readingBar() {
    const readingProgress = document.querySelector('#reading-progress-fill');
    const footerHeight = $('.p-bottom-related-posts').length ? $('.p-bottom-related-posts').height() : 0 + $('.p-main-footer').length ? $('.p-main-footer').height() : 0 + 500;
    const _this = this;

    function init() {
        if (readingProgress) {
            document.addEventListener('scroll', function(e) {
                let w = (document.body.scrollTop || document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight - footerHeight) * 100;
                readingProgress.style.setProperty('width', w + '%');
            });
        }

    }

    init();


}

readingBar();