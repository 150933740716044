$(".js-share-btn")
    .popover({
        trigger: "focus", // change to focus
        placement: "bottom",
        html: true,
        template: `
    <div class="tooltip c-share-popover" role="tooltip">
      <div class="arrow"></div>
      <div class="tooltip-inner d-flex flex-column">
        <span class="tooltip-inner__title">Partilhar</span>
        <a href="#" target="_blank" rel=”noopener noreferrer” class="c-share-popover__whatsapp s-whatsapp"> Whatsapp</a>
        <a href="#" target="_blank" rel=”noopener noreferrer” class="c-share-popover__facebook s-facebook"> Facebook </a>
        <a href="#" target="_blank" rel=”noopener noreferrer” class="c-share-popover__twitter c-twitter">Twitter</a>
        <a href="#" target="" rel=”noopener noreferrer” class="c-share-popover__link s-link">Copiar url</a>
      </div>
    </div>`,
    })
    .on("show.bs.popover", function(e) {
        //hide any visible popover
        $(".js-share-btn").not(this).popover("hide");
    })
    .on("shown.bs.popover", function(e) {
        var modal = $('.c-share-popover');
        var link = window.location;
        modal.find('.s-facebook').attr("href", `https://www.facebook.com/sharer/sharer.php?u=${link}`);
        $("a.c-share-popover__facebook").html('<span class="iconify" data-icon="eva:facebook-fill"></span><span class="s-text">Facebook</span>');
        Iconify.default.scan($('a.c-share-popover__facebook .iconify')[0]);

        modal.find('.s-whatsapp').attr("href", `https://api.whatsapp.com/send?text=${link}`).data("action", "share/whatsapp/share")
        $("a.c-share-popover__whatsapp").html('<span class="iconify" data-icon="bi:whatsapp"></span><span class="s-text">Whatsapp</span>');
        Iconify.default.scan($('a.c-share-popover__whatsapp .iconify')[0]);

        modal.find('.s-twitter').attr("href", `https://api.whatsapp.com/send?text=${link}`).data("action", "share/whatsapp/share")
        $("a.c-share-popover__twitter").html('<span class="iconify" data-icon="akar-icons:twitter-fill"></span><span class="s-text">Twitter</span>');
        Iconify.default.scan($('a.c-share-popover__twitter .iconify')[0]);

        modal.find('.s-link').attr("data-clipboard", link).addClass('js-copy-clipboard');
        $("a.c-share-popover__link").html('<span class="iconify" data-icon="ant-design:link-outlined"></span><span class="s-text">Copiar link</span>');
        Iconify.default.scan($('a.c-share-popover__link .iconify')[0]);
        $(modal.find('.s-link')[0]).on('click', function() {
            e.preventDefault();

            const copyData = $(this).data('clipboard');
            navigator.clipboard.writeText(copyData);
        });




    });