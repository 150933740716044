class BookmarksModule {
    constructor() {
        this.bookmarkBtn = $('.btn-bookmark');

        this.events();
    }

    // Events

    events() {
        $('body').on("click", ".btn-bookmark", this.bookmark.bind(this));
    }

    isBookmarked(pid) {


        let url = ra_theme.site_url + "/wp-json/ra/v1/bookmark/check/" + pid;

        return $.ajax({
            method: "GET",
            url,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        });

    }

    bookmark(e) {
        e.preventDefault();

        if (ra_theme.is_logged == '') {
            $('.p-main-navbar__login-btn').click();
            return;
        }

        let url = ra_theme.site_url + "/wp-json/ra/v1/bookmark/set/" + $(e.currentTarget).data('post-id');
        $(".btn-bookmark[data-post-id=\"" + $(e.currentTarget).data('post-id') + "\"]").toggleClass('bookmark-active');

        let _this = this;

        _this.bookmarkBtn.addClass('btn--loading');

        $.ajax({
            method: "GET",
            url,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function(results) {
            _this.bookmarkBtn.removeClass('btn--loading');

            if (results && results.success) {
                if (results.success.bookmark) {
                    $(".btn-bookmark[data-post-id=\"" + $(e.currentTarget).data('post-id') + "\"]").addClass('bookmark-active');
                } else {
                    $(".btn-bookmark[data-post-id=\"" + $(e.currentTarget).data('post-id') + "\"]").removeClass('bookmark-active');
                }
            } else {
                $(".btn-bookmark[data-post-id=\"" + $(e.currentTarget).data('post-id') + "\"]").removeClass('bookmark-active');
            }


        });
    }



}

const ra_bookmarks = new BookmarksModule();