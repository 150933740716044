class ViewVideoModal {
    constructor() {

        this.init();

    }

    init() {
        $('.c-view-video').on('shown.bs.modal', function(e) {

            $('body').append($(e.delegateTarget));
        })
    }



}

const videoModule = new ViewVideoModal();