class PostsModules {
    constructor() {
        //init  post sliders
        // init comparativo functions
        this.comparativo();
        this.coverBlockFix();
        this.events();
    }

    events() {
        $(window).on('resize', this.coverBlockFix.bind(this));
    }

    // Comparativo
    comparativo() {
        let _h = 0;

        $('.c-car-details-card__compare-container .c-car-details-card__title-container').each(function() {
            if ($(this).height() > _h) {
                _h = $(this).height();
            }
        });
        $('.c-car-details-card__compare-container .c-car-details-card__title-container').height(_h + "px");

        _h = 0;
        $('.c-car-details-card__compare-container .c-car-details-card__base-info').each(function() {
            if ($(this).height() > _h) {
                _h = $(this).height();
            }
        });
        $('.c-car-details-card__compare-container .c-car-details-card__base-info').height(_h + "px");


        let _accordions = $('.accordion');
        $('.accordion .s-section button').on('click', function() {

            if (!$(this).hasClass('intersect')) {
                let _index = $(this).parent().parent().parent().index() + 1;
                let _currentAccordion = $(this).parent().parent().parent().parent();

                _accordions.each(function() {
                    if ($(this).attr('id') != _currentAccordion.attr('id')) {
                        _currentAccordion = $(this);
                        return false;
                    }
                })

                _currentAccordion.find('.s-section:nth-child(' + _index + ') button').addClass('intersect');
                _currentAccordion.find('.s-section:nth-child(' + _index + ') button').click();
                _currentAccordion.find('.s-section:nth-child(' + _index + ') button').removeClass('intersect');

            } else {

            }


        });

    }

    coverBlockFix() {
        $('.wp-block-cover').each(function(index, el) {
            $(el).css("margin-left", 0);

            let _w = $(el).width();
            let _pw = $(document).width();

            $(el).css("margin-left", -(_pw - _w));
        });
    }

}

const postModules = new PostsModules();