class GalleryModule {

    constructor() {
        this.init();
    }

    init() {

        $(".js-c-gallery:not(.initialized)").each(function() {

            let args = {
                //cssMode: true,
                spaceBetween: 10,
                navigation: {
                    nextEl: $(this).find(".swiper-button-next")[0],
                    prevEl: $(this).find(".swiper-button-prev")[0],
                },
                // mousewheel: false,
                keyboard: true,
                slidesPerView: 1,
                grabCursor: true,
                centeredSlides: true,
                observer: true,
                observeParents: true
            };


            /*    if (($(this).data('cols') > 1)) {
                    args.breakpoints = {
                        // when window width is >= 320px
                        // when window width is >= 480px
                        480: {
                            slidesPerView: 2,

                        },
                        // when window width is >= 640px
                        991: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: $(this).data('cols'),

                        }
                    }
                }*/

            let _slider = new Swiper(this, args);

            $(this).addClass('initialized');

        });


        $('.c-gallery-model').on('shown.bs.modal', function(e) {
            let _this = this;

            $("body").append($(this));

            $(this).find(".js-c-gallery-inside:not('.swiper-container-initialized')").each(function() {

                let _gallery = new Swiper(this, {
                    //  cssMode: true,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: $(this).find(".c-gallery-inside-modal__nav-next")[0],
                        prevEl: $(this).find(".c-gallery-inside-modal__nav-prev")[0],
                    },
                    pagination: {
                        el: $(this).find(".c-gallery-inside-modal__pagination")[0],
                        type: "fraction",
                        clickable: true,
                    },
                    //    mousewheel: true,
                    keyboard: true,
                    grabCursor: true,
                    centeredSlides: true
                });

                $($(_this).find('.s-description')[0]).html("<p class=\"fade-in-right\">" + $(_gallery.slides[0]).data("caption") + "</p>");
                $($(_this).find('.s-gallery-credits')[0]).html("<p class=\"fade-in-right\">" + $(_gallery.slides[0]).data("credit") + "</p>");


                _gallery.on('slideChange', function() {
                    let _caption = $(this.slides[this.realIndex]).data("caption") ? $(this.slides[this.realIndex]).data("caption") : "";
                    let _credit = $(this.slides[this.realIndex]).data("credit") ? $(this.slides[this.realIndex]).data("credit") : "";

                    $($(_this).find('.s-description')[0]).html("<p class=\"fade-in-right\">" + _caption + "</p>");

                    $($(_this).find('.s-gallery-credits')[0]).html("<p class=\"fade-in-right\">" + _credit + "</p>");

                });

                $('.c-gallery-inside-modal__thumbnails').click(function() {

                    $('.s-gallery-thumbs').toggle();
                    $('.s-slide-container').toggle();

                });


                $('.s-gallery-thumbs .swiper-slide').on('click', function(e) {

                    _gallery.slideTo($(e.currentTarget).index(), 200);
                    $('.s-gallery-thumbs').toggle();
                    $('.s-slide-container').toggle();
                })


            });
        });

    }

}

const galleryModule = new GalleryModule();