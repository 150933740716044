class DarkModeModule {
    constructor() {
        this.isDarkMode = $('body').hasClass('ra-dark-mode');
        this.darkModeTogglerMainElement = $('.p-main-navbar .daynight');
        this.darkModeTogglerMenu = $('.p-posts-header .daynight');

        this.events();
    }

    // Events
    events() {
        this.darkModeTogglerMainElement.on('click', this.toggleDarkMode.bind(this));
        this.darkModeTogglerMenu.on('click', this.toggleDarkMode.bind(this));

        if ($('.daynight input').prop('checked')) {
            $('body').addClass('ra-dark-mode');
        }


    }

    toggleDarkMode(e) {
        e.preventDefault();
        jQuery('body').toggleClass('ra-dark-mode');
        jQuery(document).trigger("toggleDarkmode", jQuery('body').hasClass('ra-dark-mode'));

        if (jQuery('body').hasClass('ra-dark-mode')) {
            $('.daynight input').prop('checked', true);
        } else {
            $('.daynight input').prop('checked', false);
        }

        jQuery.ajax({
            type: "post",
            dataType: "json",
            url: ajaxurl,
            data: { action: "toggle_dark_mode" },
            success: function(msg) {
                if (msg.dark_mode) {

                    jQuery('body').addClass('ra-dark-mode');
                } else {
                    jQuery('body').removeClass('ra-dark-mode');



                }

            }
        });

    }
}

new DarkModeModule();