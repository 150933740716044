class BrandModelSelector {
    constructor() {
        this.brandModelSelector = $('.c-brand-model-selector');
        this.brandSelector = $('.c-brand-model-selector #s-brand');
        this.modelSelector = $('.c-brand-model-selector #s-model');

        this.backBtn = $('#brand-selector-modal .back-btn');
        this.closeBtn = $('#brand-selector-modal .close-btn');


        this.selectedBrand = $('#brand-selector-list li.selected').data('value');
        this.selectedModel = $('#model-selector-list li.selected').data('value');

        this.modelSelector.data('value', $('#model-selector-list li.selected').data('value'));
        this.brandSelector.data('value', $('#brand-selector-list li.selected').data('value'));

        this.brandSelector.each(function() {
            if ($(this).val() == 'Todas as marcas') {
                $(this).siblings('#s-model').prop("disabled", true);
                $(this).siblings('.btn-dark').prop("disabled", true);
            }
        });

        this.url = $('#model-selector-list li.selected').data('url');

        this.events();
    }

    events() {

        this.brandModelSelector.on('submit', this.goToModelPage.bind(this));

        this.backBtn.on('click', this.hideModelSelector.bind(this));

        this.brandSelector.on('click', this.hideModelSelector.bind(this));

        this.modelSelector.on('click', this.showModels.bind(this));

        $('#brand-selector-list li').on('click', this.showModelsFromBrands.bind(this));

        $('body').on('click', '#model-selector-list li', this.selectModel.bind(this));

        $('#brand-selector-modal').on('shown.bs.modal', function(e) {});

    }

    // Events

    hideModelSelector(e) {
        e.preventDefault();
        $('#brand-selector-modal').removeClass('model-selector-display');
    }

    goToModelPage(e) {
        e.preventDefault();
        window.location = this.url;
    }

    selectModel(e) {
        e.preventDefault();
        this.modelSelector.val($(e.currentTarget).data('search-target-value'));


        if ($(e.currentTarget).data('value') != -1) {
            this.selectedModel = $(e.currentTarget).data('value');
            this.url = $(e.currentTarget).data('url');
            window.location = this.url;
        }

        $('#brand-selector-modal').modal('hide');
    }

    showModels(e) {

        let _isNew = (this.selectedModel != $(e.currentTarget).data('value'));


        $('#brand-selector-modal').addClass('model-selector-display');

        if (!_isNew) {
            return;
        }

        if ($(e.currentTarget).data('search-target-value')) {
            this.brandSelector.val($(e.currentTarget).data('search-target-value'));
            this.selectedBrand = $(e.currentTarget).data('value');
            this.url = $(e.currentTarget).data('url');
        }


        let _url = ra_theme.site_url + "/wp-json/ra/v1/brand/get/" + this.selectedBrand;
        $('.model-display .models-list').empty();
        $('.model-display .models-list').addClass('loading');


        $.ajax({
            method: "GET",
            url: _url,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function(results) {
            let _html = '';

            for (const i in results) {
                _html += `<li data-search-target-value="${results[i]['name']}" class="fade-in-right" data-value="${results[i]['id']}" data-url="${results[i]['url']}">
                <span class="s-brand-title">${results[i]['name']}</span>
               </li>`;
            }

            $('.model-display .models-list').removeClass('loading');
            $('.model-display .models-list').html(_html);
        });
    }

    showModelsFromBrands(e) {

        let _isNew = (this.selectedBrand != $(e.currentTarget).data('value'));
        this.modelSelector.prop("disabled", false);
        $('.c-brand-model-selector .btn-dark').prop("disabled", false);

        $('#brand-selector-modal').addClass('model-selector-display');

        if (!_isNew) {
            return;
        }

        if ($(e.currentTarget).data('search-target-value')) {
            this.brandSelector.val($(e.currentTarget).data('search-target-value'));
            this.selectedBrand = $(e.currentTarget).data('value');
            this.url = $(e.currentTarget).data('url');
        }


        let _url = ra_theme.site_url + "/wp-json/ra/v1/brand/get/" + this.selectedBrand;
        $('.model-display .models-list').empty();
        $('.model-display .models-list').addClass('loading');


        $.ajax({
            method: "GET",
            url: _url,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function(results) {
            let _html = '';

            for (const i in results) {
                _html += `<li data-search-target-value="${results[i]['name']}" class="fade-in-right" data-value="${results[i]['id']}" data-url="${results[i]['url']}">
                <span class="s-brand-title">${results[i]['name']}</span>
               </li>`;
            }

            $('.model-display .models-list').removeClass('loading');
            $('.model-display .models-list').html(_html);
        });
    }
}

const ra_brand_model_selector = new BrandModelSelector();