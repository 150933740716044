class CommentsModule {
    constructor() {
        this.commentModal = $('#comments-drawer');

        if (!this.commentModal.length) {
            return;
        }

        this.commentMainWrapper = $('.comments-area');
        this.commentsListWrapper = $('.comments-area .comments-container:not(.s-more-popular) ol.comment-list');
        this.commentsLoadMoreBtn = $('.comments-area .comments-container:not(.s-more-popular) .s-load-more-comments');
        this.commentsFormWrapper = $('#respond.comment-respond');
        this.commentsCancelReply = $("#cancel-comment-reply-link");

        this.commentsModalTrigger = $(".js-open-comments-drawer");
        this.commentsFilterTrigger = $(".s-comment-filter-trigger");

        this.commentsFormTrigger = $(".s-show-submit-form");
        this.commentsFormCloseTrigger = $(".s-hide-submit-form");

        this.postID = this.commentMainWrapper.data('post-id');

        this.commentForm = $("#commentform");
        this.commentID = '';

        this.currentPage = 0;
        this.loading = false;
        this.filterByDate = true;
        this.loadMore = false;

        this.currentPageMostPopular = 0;
        this.loadMoreMostPopular = false;


        this.commentsListWrapper.html('');
        this.updateComments();

        this.previousState = null;

        this.commentsTitle = 'Comentários ';
        this.commentsNumber = 0;

        this.events();
    }

    // Events
    events() {



        //  this.commentsLoadMoreBtn.on("click", this.loadMoreComments.bind(this));
        // this.commentsCancelReply.on("click", this.replaceReplyForm.bind(this));

        $("#comments-drawer .modal-body").on('scroll', this.loadMoreOnScroll.bind(this));

        $('body').on('click', '.comment-see-reply', this.seeReplies.bind(this));
        $('body').on('click', '.comment-reply-link', this.placeReplyForm.bind(this));
        $('body').on('click', '.s-upvote', this.upvote.bind(this));
        $('body').on('click', '.s-in-reply-to', this.scrollToReply.bind(this));

        this.commentsFilterTrigger.on('click', this.filterResults.bind(this));
        this.commentsFormTrigger.on('click', this.toggleCommentForm.bind(this));

        this.commentForm.on('submit', this.addComment.bind(this));

        this.commentsNumber = Number($('.comments-number').html());

        $('#comment').keypress(function() {
            $('.comment-error').remove();
        });
    }

    closeCommentForm(e) {
        this.commentsFormWrapper.removeClass('active');
    }

    toggleCommentForm(e) {
        e.preventDefault();
        $('.comment-error').remove();
        if (this.commentsFormWrapper.hasClass('active')) {
            this.commentsFormWrapper.addClass('fade').removeClass('active');

            this.commentID = '';
            $('#reply-title').html('Comentar:');

            $("ol.comment-list li.comment").removeClass('focus');
            $("ol.comment-list").removeClass('focusing');
            $(e.currentTarget).removeClass('btn-dark').addClass('btn-primary');
            $(e.currentTarget).html('Comentar');

            this.commentsFormWrapper.parent().removeClass('active');



        } else {
            $("ol.comment-list li.comment").removeClass('focus');
            $("ol.comment-list").removeClass('focusing');

            if (this.commentID) {
                $('#reply-title').html('Responder a ' + $("#comment-" + this.commentID + " .comment-author").html());

            } else {
                $('#reply-title').html('Comentar:');

            }

            this.commentsFormWrapper.parent().addClass('active');


            this.commentsFormWrapper.addClass('active').addClass('show');
            $(e.currentTarget).removeClass('btn-primary').addClass('btn-dark');
            $(e.currentTarget).html('Cancelar')


        }

    }

    resetSearchVariables() {
        this.currentPage = 0;
        this.loading = false;
        this.loadMore = false;
    }

    filterResults(e) {
        let el = $(e.currentTarget);
        let currentHtml = $('#comments-drawer .modal-body').html();

        if (this.filterByDate) {
            this.filterByDate = false;

            this.commentsFilterTrigger.html("Ver mais recentes");
            $('.comments-area .comments-container:not(.s-more-popular) ol.comment-list').addClass('d-none');
            $('.comments-area .comments-container.s-more-popular ol.comment-list').removeClass('d-none');

            if (!$('.comments-area .comments-container.s-more-popular ol.comment-list')[0].children.length) {
                this.updateComments();
            }

            $('h2.comments-title').html(`Mais populares <span class="comments-number">${this.most_popular}</span>`);
        } else {
            this.filterByDate = true;

            this.commentsFilterTrigger.html("Ver mais populares");
            $('.comments-area .comments-container:not(.s-more-popular) ol.comment-list').removeClass('d-none');
            $('.comments-area .comments-container.s-more-popular ol.comment-list').addClass('d-none');

            // $('h2.comments-title').html(`Comentários <span class="comments-number">${this.commentsNumber}</span>`);


        }
        return;

    }

    _scrollToReply(_target) {

        $('#comments-drawer .modal-body').scrollTop(_target.position().top);
        $(_target).removeClass('fade-in-right').addClass('blink-1');
        window.setTimeout(function() {
            $(_target).removeClass('blink-1');
        }, 800);
    }

    scrollToReply(e) {
        e.preventDefault();
        var _target = $($(e.currentTarget).attr('href'));
        this._scrollToReply(_target);

    }

    loadMoreOnScroll(event) {
        const element = event.target;
        if ((!this.loading && this.loadMore && this.filterByDate) || (!this.loading && this.loadMoreMostPopular && !this.filterByDate)) {
            if (element.scrollHeight <= element.scrollTop + element.clientHeight + 50) {
                this.loading = true;
                if (this.filterByDate) {
                    this.currentPage += 1;
                } else {
                    this.currentPageMostPopular += 1;
                }

                this.updateComments();
            }
        }
    }

    seeReplies(e) {
        e.preventDefault();
        let currentPage = $(e.target).data("page") ? $(e.target).data("page") : 0;
        let commentID = $(e.target).data("comment");

        let data = {
            page: currentPage,
            //order : ''
        };
        let _this = this;
        $(e.target).attr('disabled', true);
        $(e.target).html("A carregar...");

        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/comment/reply/" + commentID,
            data,
        }).done(function(results) {
            $(e.target).attr("data-page", currentPage + 1);
            $('#' + $(e.target).data("children")).append(_this.renderComments(results.comments, true)).removeClass('d-none');

            if (!results.hasmore) {
                $(e.target).addClass('d-none');
            } else {
                $(e.target).removeClass('d-none');
            }

            $(e.target).html("Ver mais respostas");
            $(e.target).attr('disabled', null);
        });

    }

    updateReplies(commentID) {
        let data = {
            page: 0,
            //order : ''
        };
        let _this = this;
        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/comment/reply/" + commentID,
            data,
        }).done(function(results) {
            $('#children-' + commentID).append(ra_comments.renderComments(results.comments, true)).removeClass('d-none');
        });
    }

    placeReplyForm(e) {
        e.preventDefault();
        $('.comment-error').remove();
        if (ra_theme.is_logged == '') {
            $('.p-main-navbar__login-btn').click();
            return;
        }

        this.commentID = $(e.target).data("commentid");


        if (!this.commentsFormWrapper.hasClass('active')) {
            this.commentsFormWrapper.addClass('active').addClass('show');
            this.commentsFormTrigger.html('Cancelar');
        }
        $('#reply-title').html('Responder a ' + $("ol.comment-list:not(d-none) #comment-" + this.commentID + " .comment-author").html());
        $("ol.comment-list:not(d-none) li.comment").removeClass('focus');
        this._scrollToReply($("#comment-" + this.commentID));

        $("#comment-" + this.commentID).addClass('focus');
        $("ol.comment-list:not(d-none)").addClass('focusing');
    }

    renderComment(comment_id, content, author_name, avatar, trophy, post_id, date, upvotes, upvoted, haschildren, isreply, parent_id, parent_author) {
            let hasChildrenReply = haschildren && isreply;
            let hasChildrenComment = haschildren && !isreply;


            return `<li id="comment-${comment_id}" class="comment fade-in-right" >
                    <article id="div-comment-${comment_id}" class="comment-container">
                        <div class="comment-content">
                            <div class="comment-header">
                                <strong class="comment-author">${author_name}</strong>
                                ${trophy ? `<span class="s-trophy"><span class="iconify" data-icon="ion:trophy"></span></span>` : ``}
                                <span class="comment-date">
                                    ${date}
                                </span>
                            </div>
                            <div class="comment-body">
                                ${isreply?`<a href="#comment-${parent_id}" class="s-in-reply-to">em resposta a&nbsp;<strong>${parent_author}</strong></a>`:''}
                                ${content}
                            </div>
                            <div class="comment-footer">
                                <a rel="nofollow" class="comment-reply-link" href="#comment-${comment_id}" data-commentid="${comment_id}" data-postid="${post_id}" data-belowelement="comment-${comment_id}" data-respondelement="respond" data-replyto="Responder a ${author_name}" aria-label="Responder a ${author_name}"><span class="iconify" data-icon="carbon:reply"></span> Responder</a>
                            <button class="btn s-upvote  ${upvoted ? `upvoted-by-user` : ``}" data-comment-id="${comment_id}">
                                <span class="iconify" data-icon="heroicons-outline:arrow-sm-up"></span> <span class="s-value">${upvotes}</span>
                            </button>
                            
                            </div>
                           
                        </div>
                        ${hasChildrenReply?`<button class="comment-see-reply reply" data-children="children-${comment_id}" data-comment="${comment_id}">Ver respostas</button>`:``}
                         
                    </article>
                   
                </li>
                <ol id="children-${comment_id}" class="children d-none" ></ol>
                ${hasChildrenComment?`<button class="comment-see-reply" data-children="children-${comment_id}" data-comment="${comment_id}">Ver respostas</button>`:``}
                `;
    }
    
    renderComments(comments,isreply) {
        let html = '';
        
        comments.forEach((element) => {
            let c = element.comment;
            let hasChildren = element.has_children;
            
            html += this.renderComment(c.id, c.content, c.author, c.avatar, c.most_upvoted, this.postID, c.date, c.upvotes, c.upvoted, hasChildren,isreply,c.parent_id, c.parent_author );
          
        });
        
        return html;
    }
    

    updateComments(reset) {
      
        let data = {
            order: this.filterByDate ? true : false,
            page: this.filterByDate ? this.currentPage : this.currentPageMostPopular
        };
       
        
        let _this = this;
        if (!reset) {
            $('ol.comment-list').addClass('loading');
        }
        
        $('.p-posts-header .js-open-comments-drawer .s-text').html("");
        $('.p-posts-header .js-open-comments-drawer ').addClass("btn--loading");

        return $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/comment/get/" + this.postID,
            data,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function (results) {
            $('.comment-list').removeClass('loading');
            $('.p-posts-header .js-open-comments-drawer ').removeClass("btn--loading");
           
            if (results.number_of_comments && results.number_of_comments > 0) {
                //commments available
                let comment_term = 'comentário';
                
                $('.p-posts-header .js-open-comments-drawer .s-text').html(results.number_of_comments);
                $('.btn-comment[data-post-id="'+results.pid+'"] .s-text').html(results.number_of_comments);
                $('.no-comments-available').addClass('d-none');
                
                if (results.number_of_comments > 1) {
                    comment_term = 'comentários';
                }
                $('#comments-drawer h2.comments-title').html('<span class="comments-number">'+results.number_of_comments +'</span> '+comment_term);
                
            } else {
                //no commments available
                $('.comments-area .comments-title').html('Sem comentários');
                $('.no-comments-available').removeClass('d-none');
                $('.p-posts-header .js-open-comments-drawer .s-text').empty();
                $('.btn-comment[data-post-id="'+results.pid+'"] .s-text').empty();
            }
         
            
            if (results.post_title) {
                $('.comments-area__post-title').html(results.post_title);
                
            }
            
            if (results.post_excerpt) {
                $('.comments-area__post-excerpt').html(results.post_excerpt);
            }
            
            if (!results.hasmore) {
                if (_this.filterByDate) {
                    _this.loadMore = false;
                    
                } else {
                    _this.loadMoreMostPopular = false; 

                }
            } else {
                if (_this.filterByDate) {
                    _this.loadMore = true;
                } else {
                    _this.loadMoreMostPopular = true;  
                }
            } 
            
            if (_this.filterByDate) { 
                if (reset) {
                    $('ol.comment-list').empty();
                }
                $('.comments-container:not(.s-more-popular) ol.comment-list').append(_this.renderComments(results.comments, false));
                
                if (results.most_popular) {
                    _this.commentsFilterTrigger.removeClass('disabled');
                } else {
                    _this.commentsFilterTrigger.addClass('disabled');
                }
            } else {
                
                if (results.most_popular) {
                    _this.commentsFilterTrigger.removeClass('disabled');
                } else {
                    _this.commentsFilterTrigger.click();
                    _this.commentsFilterTrigger.addClass('disabled');
                }
                
                $('.comments-container.s-more-popular ol.comment-list').append(_this.renderComments(results.comments, false));
            }
            
            if (results.most_popular) {
                _this.most_popular = results.most_popular;
            }
            
            
            _this.loading = false;
        });
    }
    
    toggleError(errors) {
        
        let error_message = errors.comment_duplicate || errors.commment_not_allowed || errors.comment_flood || "Ocorreu um erro, por favor faça refresh à página";
        $('.comment-error').remove();
        $('#reply-title').after('<span class="comment-error">'+error_message+'</span>');
    }

    addComment(e) {
        e.preventDefault();

        let is_reply = (this.commentID)?true:false;
        let url = ra_theme.site_url + "/wp-json/ra/v1/comment/add/" + this.postID;
        
        if (is_reply) {
            url = ra_theme.site_url + "/wp-json/ra/v1/comment/reply/"+this.postID+"/"+ this.commentID;
        }
        
        let data = {
            content:$('#comment').val()
        };
        let _this = this;
        
        $.ajax({
            method: "POST",
            url,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
            data,
        }).done(function (results) {
            if (results.errors) {
                _this.toggleError(results.errors);
                return;
            }
            
            $('#comment').val('');
            $("#s-show-submit-form").click();
            
            if (!is_reply) {
                _this.currentPage = 0;
                let _res = results;
               
                _this.updateComments(true).then(()=>{
                    $('#comments-drawer .modal-body').scrollTop($("#comment-" + _res.success.comment.id).position().top);
                    setTimeout(function(){
                        $("#comment-" + _res.success.comment.id).removeClass('fade-in-right').addClass('blink-1');
                    }, 300);
                });
                
               // $('#comments-drawer ol.comment-list').prepend(_this.renderComments([results.success], false));
               
                    
            } else {
                
                let $childrenElement = $("#children-" + results.success.comment.parent_id);
                
                if ($('[data-children="children-' + results.success.comment.parent_id+'"]').length) {
                    if ($childrenElement.hasClass('d-none')) {
                        $('[data-children="children-' + results.success.comment.parent_id+'"]').trigger('click');
                    }
                } else {
                    $childrenElement.removeClass('d-none');
                    _this.updateReplies(results.success.comment.parent_id);
                }
            }
        });
        
    }
    
    upvote(e) {
        e.preventDefault();
        
        if (ra_theme.is_logged == '') {
            $('.p-main-navbar__login-btn').click();
            return;
        }
        

        let url = ra_theme.site_url + "/wp-json/ra/v1/comment/upvote/" + $(e.currentTarget).data('comment-id');
        $(e.currentTarget).toggleClass('upvoted-by-user');
        
        let _this = this;
        let _target = $(e.currentTarget);
        
        _target.attr('disabled');
        
        $.ajax({
            method: "POST",
            url,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function (results) {
            if (results.success ) {
                $($(e.currentTarget).find('.s-value')[0]).html(results.success.value);
                _target.attr('disabled',null);
                
                if (results.upvoted) {
                    return _target.addClass('upvoted-by-user'); 
                }
                
                return _target.removeClass('upvoted-by-user'); 
                
            }
          
        });
    }
}

const ra_comments = new CommentsModule();