class anchorModule {
    anchors;

    constructor() {
        //init  post sliders
        // init comparativo functions
        this.anchors = 0;
        this.build_tabs();

        //   this.events();
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= 100 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
    onScroll(event) {
        if (this.isInViewport(event.data)) {
            $('.tab-nav-item.active').removeClass('active');
            $('.tab-nav-item[href="' + $(event.data).attr('id') + '"]').addClass('active');
            const _base_url = $($(event.data).parents('.wp-post')[0]).data('post-url');

            window.history.pushState({}, '', _base_url + $(event.data).attr('id') + '/');
        }
    }

    build_tabs() {
        //Build navigation
        const _this = this;
        if ($(".ra-anchors-nav:not('.loaded')").length) {
            let _index = 0;
            let _is_first = true;
            $(".ra-anchors-nav:not('.loaded')").each(function() {
                /* var _id = '';
                 var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                 var charactersLength = characters.length;
                 for (var i = 0; i < 5; i++) {
                     _id += characters.charAt(Math.floor(Math.random() *
                         charactersLength));
                 }*/

                let _nav_items = $(this).find(".tab-nav-item");
                const _active_anchor = $($(this).parents("[data-active-tab]")[0]).attr('data-active-tab');
                const _base_url = $($(this).parents('.wp-post')[0]).data('post-url');


                _nav_items.on('click', function(e) {
                    e.preventDefault();
                    let _destination = $('#' + $(this).attr('href'));

                    $(this).siblings().removeClass('active');
                    $(this).addClass('active');

                    $('html, body').animate({
                        scrollTop: _destination.offset().top - 80
                    }, 0);

                    window.history.pushState({}, '', _base_url + $(this).attr('href')) + '/';
                });

                _nav_items.each(function() {
                    if (_active_anchor == '') {
                        if (_is_first) {
                            window.history.pushState({}, $(this).html(), _base_url + $(this).attr('href') + '/');
                            $(this).addClass('active');
                            _is_first = false;
                        }
                    } else {
                        if ($(this).attr('href') == _active_anchor) {
                            $(this).addClass('active');
                        }
                    }
                });

                if (_active_anchor !== '') {
                    $('html, body').animate({
                        scrollTop: $('#' + _active_anchor).offset().top - 80
                    }, 0);
                }

                $(this).addClass('loaded');


            });

        }

        //Build gallery
        $(".ra-anchor").each(function(index, item) {
            let _tab_name = $(item).attr('id');

            $(this).find(".img-container img").each(function(index, item) {
                let attr = "";
                if (!index) {
                    attr += " data-tab-anchor=\"" + _tab_name + "\" ";
                }
                $("<div class=\"swiper-slide\"" + attr + ">" + item.outerHTML + "</div>").appendTo(".c-thumbs-gallery__thumbs .swiper-wrapper, .c-thumbs-gallery__full .swiper-wrapper");
            });
            $(document).on("scroll", item, _this.onScroll.bind(_this));


        });



        const swiperthumbs = new Swiper(".c-thumbs-gallery__thumbs", {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
        });

        const swiper2 = new Swiper(".c-thumbs-gallery__full", {
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            loop: true,
            thumbs: {
                swiper: swiperthumbs,
            },
        });
    }

}

const anchor_module = new anchorModule();