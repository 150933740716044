class AlertsModule {
    constructor() {
        this.followBtn = $('.c-notifications-btn');

        this.events();
    }

    // Events

    events() {
        $('body').on("click", ".c-notifications-btn", this.setAlert.bind(this));
    }

    setAlert(event) {

        if (ra_theme.is_logged == '') {
            $('.p-main-navbar__login-btn').click();
            return;
        }
        let $elem = $(event.currentTarget);

        $elem.addClass('btn--loading');
        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/notifications/follow/" + $elem.data('item-type') + "/" + $elem.data('item-id'),
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function(results) {
            $elem.removeClass('btn--loading');


            const follow_text = "<span class='d-none d-md-block'>Alerta ativado! Receberá uma notificação sempre que um artigo com o tópico “" + $elem.data('item-name') + "” for publicado.</span><span class='d-block d-md-none'>Alerta ativado!</span>";

            const unfollow_text = "<span class='d-block d-md-none'>Receba alertas sobre este tópico.</span><span class='d-none d-md-block'>Receba alertas sobre este tópico. Pode desativar em qualquer momento.</span>";


            if (results && results.success) {
                if (results.followed) {
                    $elem.addClass('active');
                    $elem.attr('data-original-title', follow_text);
                    $('#' + $elem.attr('aria-describedby')).find('.tooltip-inner').html(follow_text);

                } else {
                    $elem.removeClass('active');
                    $elem.attr('data-original-title', unfollow_text);

                    $('#' + $elem.attr('aria-describedby')).find('.tooltip-inner').html(unfollow_text);


                }

            } else {
                $elem.removeClass('active');
                $elem.attr('data-original-title', unfollow_text);
                $('#' + $elem.attr('aria-describedby')).find('.tooltip-inner').html(unfollow_text);

            }
        });
    }
}

const ra_bookmarks = new AlertsModule();