class PostsHeader {
    constructor() {

        this.settings = {
            element: $(".p-posts-header"),
            title: '',
            id: '',
        }

        this.events();
    }

    showHeader() {
        this.settings.element.addClass('show');
    }

    hideHeader() {
        this.settings.element.removeClass('show');
    }

    update(post_id, title, term) {
        $('.p-posts-header .p-posts-header__title span').html(`
          <strong class="category">${term} </strong> 
          ${title} `);
    }

    start() {
        if (this.settings.element) {
            document.addEventListener('scroll', onScroll);

            function onScroll() {
                var scrollPosition = window.scrollY,
                    showHeaderPosition = 200;

                // Determine if position is at a certain point
                if (scrollPosition >= showHeaderPosition) {
                    this.showHeader();
                } else {
                    this.hideHeader();
                }
            }
        }
    }

    onScroll() {
            var scrollPosition = window.scrollY,
                showHeaderPosition = 200;

            // Determine if position is at a certain point
            if (scrollPosition >= showHeaderPosition) {
                this.showHeader();
            } else {
                this.hideHeader();
            }

        }
        // Events
    events() {


        $(document).on('scroll', this.onScroll.bind(this));



    }
}


const postsHeader = new PostsHeader();