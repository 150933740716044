class UserBookmarksSlider {
    constructor() {

        this.settings = {
            slidesPerView: 1,
            noSwiping: true,
            noSwipingClass: 'swiper-slide',

            breakpoints: {
                576: {
                    // Optional parameters
                    navigation: {
                        nextEl: ".c-user-bookmarks-slider__nav-next",
                        prevEl: ".c-user-bookmarks-slider__nav-prev",
                    },
                    scrollbar: {
                        el: ".swiper-scrollbar",
                        hide: true,
                    },

                    grabCursor: true,
                    noSwiping: false,
                    slidesPerView: 2.3,
                    spaceBetween: 20,
                    watchSlidesVisibility: true,
                },
            },
        }
        this.events();
    }

    // Events
    events() {
        this.start();
    }

    start() {
        new Swiper(".js-slider-user-bookmarks", this.settings);
    }


}

class EditProfileForm {
    constructor() {
        this.form = $("#edit-profile-form");
        this.editBtn = $(".js-edit-profile");
        this.saveBtn = $(".js-save-profile");
        this.cancelBtn = $(".js-cancel-profile");
        this.deleteProfileBtn = $(".js-delete-profile");
        this.avatarBtn = $('#edit-profile-form .js-avatar-btn');
        this.inputs = $("#edit-profile-form input");

        this.avatarInput = $('#edit-profile-form input[name="avatar"]');
        this.firstnameInput = $('#edit-profile-form input[name="firstname"]');
        this.firstnameDefaultVal = $('#edit-profile-form input[name="lastname"]').val();
        this.lastnameInput = $('#edit-profile-form input[name="lastname"]');
        this.lastnameDefaultVal = $('#edit-profile-form input[name="lastname"]').val();

        this.emailInput = $("#edit-profile-form input[name='email']");
        this.emailDefaultVal = $("#edit-profile-form input[name='email']").val();
        this.passwordInput = $("#edit-profile-form input[name='password']");
        this.passwordDefaultVal = $("#edit-profile-form input[name='password']").val();
        // run events as soon object is created
        this.events();
    }

    // Events
    events() {
        this.editBtn.on("click", this.editForm.bind(this));
        this.deleteProfileBtn.on("click", this.deleteProfile.bind(this));
        this.cancelBtn.on("click", this.cancelEdit.bind(this))
        this.saveBtn.on("click", this.saveProfile.bind(this))
        this.avatarBtn.on("click", this.openFileUpload.bind(this))

        this.firstnameInput.on("keyup", this.setFieldAsChanged.bind(this))
        this.lastnameInput.on("keyup", this.setFieldAsChanged.bind(this))
        this.passwordInput.on("keyup", this.setFieldAsChanged.bind(this))
        this.emailInput.on("keyup", this.setFieldAsChanged.bind(this))



        this.avatarInput.on("change", this.updateAvatarPreview.bind(this))

    }

    updateAvatarPreview(evt) {

        let file = evt.currentTarget.files[0]
        if (file) {
            $('#edit-profile-form .avatar').attr('src', URL.createObjectURL(file));
            $('#edit-profile-form .avatar').attr('srcset', '');
            $('#edit-profile-form .avatar').addClass('changed');

        } else {
            $('#edit-profile-form .avatar').removeClass('changed');

        }

    }

    // Methods
    editForm() {
        this.form.addClass('edit-mode');
        this.inputs.prop('disabled', false);
        // clear password
        this.passwordInput.val('');
    }

    cancelEdit() {
        this.form.removeClass('edit-mode');
        this.inputs.prop('disabled', true);
        this.resetDefaultValues();
    }

    saveProfile() {

        let data = {};
        let _this = this;
        var formData = new FormData();

        this.inputs.each((i, el) => {
            let $this = $(el);
            if ($this.hasClass("changed")) {
                formData.append($this.attr('name'), $this.val());
            }
        });
        //image upload
        if ($('#edit-profile-form .avatar').hasClass('changed')) {
            formData.append("file", this.avatarInput[0].files[0], 'avatar.png');
        }

        // dont real password here. 
        $.ajax({
            method: "POST",
            url: ra_theme.site_url + "/wp-json/ra/v1/edit",
            processData: false,
            contentType: false,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
            data: formData,
        }).done(function(data) {
            $('form#edit-profile-form input.is-invalid').removeClass('is-invalid');
            $('form#edit-profile-form input+p.invalid-feedback').remove();

            if (data.errors) {
                if (data.errors.invalid_password) {
                    _this.passwordInput.addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_password[0] + '</p>');
                }
                if (data.errors.invalid_email) {
                    _this.emailInput.addClass('is-invalid').after('<p class="invalid-feedback fade-in-right">' + data.errors.invalid_email[0] + '</p>');
                }
                if (data.errors.invalid_firstname) {
                    _this.firstnameInput.addClass('is-invalid').after('<p class="invalid-feedback fade-in-right">' + data.errors.invalid_firstname[0] + '</p>');

                }
                if (data.errors.invalid_lastname) {
                    _this.lastnameInput.addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_lastname[0] + '</p>');
                }
                if (data.errors.invalid_form) {
                    $('form#loginform #username').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_form[0] + '</p>');
                }
                return;
            }

            if (data.success) {
                $('#edit-profile-form .avatar').removeClass('changed');
                $('#edit-profile-form input.changed').removeClass('changed');
                _this.form.removeClass('edit-mode');
                _this.inputs.prop('disabled', true);
            }



        });
        // replacing to demo string
        this.passwordInput.val(this.passwordDefaultVal);

    }

    resetDefaultValues() {
        this.firstnameInput.val(this.firstnameDefaultVal);

        this.lastnameInput.val(this.lastnameDefaultVal);

        this.emailInput.val(this.emailDefaultVal);
        // this is not the real password, just demo string for visual porpuses
        this.passwordInput.val(this.passwordDefaultVal);
    }

    openFileUpload() {
        // simulate click file input
        this.avatarInput.click();

        // #TODO preview image


        console.log(this.avatarInput);
    }

    deleteProfile() {
        $('.u-delete-notice').html('<strong>Aviso:</strong> A remover todos os seus dados.<br/>Por favor, aguarde e não feche esta página<br/>Irá ser redirecionado para a homepage quando estiver concluído.');
        $('.js-delete-profile').remove();

        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/remove",
            processData: false,
            contentType: false,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function(data) {
            if (data.success) {
                $('.u-delete-notice').html('<strong>Aviso:</strong>' + data.success);
                window.location.reload();
            } else {
                $('.u-delete-notice').html('<strong>Aviso:</strong>' + data.error);
            }


        });

    }

    setFieldAsChanged(e) {
        $(e.target).addClass("changed");
    }
}

class AlertsSelector {
    constructor() {
        this.pickers = $(".notification-picker");
        this.searchSelectors = $(".search-selectors");

        this.events();
    }

    events() {
        this.pickers.on('click', this.setAlert.bind(this));
        this.searchSelectors.on('keyup', this.searchSelector.bind(this));

        $('.ra-user-area__side-nav a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
            history.pushState({}, '', $(e.currentTarget).attr('href').replace('#', ''));
            postsSlider.start();
        });


    }

    searchSelector(event) {

        let $elem = $(event.currentTarget);
        let value = $elem.val().toLowerCase();
        let target = $($elem.data("search-target")).find("[data-search-target-value]");
        if (value.length) {
            console.log('value:' + value);
            target.each((i, el) => {
                let _targetValue = $(el).data("search-target-value").toLowerCase();

                let _condition = !_targetValue.includes(value);

                if (value.length == 1) {
                    console.log('starts:' + value);

                    _condition = !_targetValue.startsWith(value);
                    console.log('starts with:' + _targetValue.startsWith(value));

                }

                if (_condition) {


                    $(el).removeClass('fade-in-right').addClass('fade-out-right');
                    setTimeout(function() {
                        $(el).addClass('d-none');
                    }, 200);

                } else {
                    $(el).removeClass('fade-out-right').addClass('fade-in-right');
                    $(el).removeClass('d-none');
                }
            });
        } else {
            target.removeClass('fade-out-right').addClass('fade-in-right');

            target.removeClass('d-none');
        }

    }

    setAlert(event) {
        let $elem = $(event.currentTarget);
        $elem.toggleClass('active');

        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/notifications/follow/" + $elem.data('item-type') + "/" + $elem.data('item-id'),
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },
        }).done(function(results) {
            if (results.success) {
                if (results.followed) {
                    $elem.addClass('active');
                } else {
                    $elem.removeClass('active');
                }

            }
        });
    }

}

class BookmarksArea {
    constructor() {
        this.bookmarks = $(".s-bookmarks-list.row");
        this.currentPage = 0;
        this.numberOfBookmarks = 2;
        this.hasMore = true;
        this.loading = false;

        this.events();
        this.loadBookmarks();
    }

    events() {
        $(document).on('scroll', this.loadMoreOnScroll.bind(this));

    }

    renderBookmark() {

    }


    loadMoreOnScroll(event) {
        const element = event.target;
        if (!this.loading && this.hasMore) {

            if ($(window).scrollTop() - $('.s-bookmarks-list').height() <= 0) {
                this.loading = true;
                this.currentPage += 1;
                this.loadBookmarks();
            }
        }
    }

    loadBookmarks() {
        let _this = this;
        _this.loading = true;
        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/bookmark/get/" + this.currentPage,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            },

        }).done(function(data) {
            if (data.errors) {

                _this.loading = false;
                return;
            }
            if (data.bookmarks.length) {
                data.bookmarks.forEach(element => {
                    _this.bookmarks.append('<div class="col-sm-12 col-lg-6 col-xl-6 d-flex fade-in-right">' + element + '</div>');
                });
                _this.hasMore = data.hasMore;

            }

            ra_lazyload.fetchImages();
            ra_lazyload.events();

            _this.loading = false;
        });
    }


}

const alertsSelector = new AlertsSelector();

const editProfileForm = new EditProfileForm();

const bookmarksArea = new BookmarksArea();

const postsSlider = new UserBookmarksSlider();