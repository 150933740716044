class TabModule {
    tabs;

    constructor() {
        //init  post sliders
        // init comparativo functions
        this.tabs = 0;
        this.build_tabs();
        //   this.events();
    }

    build_tabs() {
        //Build navigation
        if ($(".tabs-block:not('.loaded')").length) {
            let _index = 0;
            $(".tabs-block:not('.loaded')").each(function() {
                var _id = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < 5; i++) {
                    _id += characters.charAt(Math.floor(Math.random() *
                        charactersLength));
                }

                let _parent = $(this).closest('.post-body');
                let _active_tab = $($(this).parents("[data-active-tab]")[0]).attr('data-active-tab');

                let _this = $(this);

                let _children = $(this).find(".tab-item");


                $(this).attr('tab-id', _id);

                _parent.before('<div class="post-nav-bar col-12 col-md-3 float-left"></div>');
                _parent.addClass("col-12 col-md-9 pl-0 pr-0");
                _parent.addClass("float-left");

                _parent.after('<div class="tab-wrapper d-flex flex-wrap"></row>');

                let _nav = "<nav class=\"post-nav\" target-id=\"" + _id + "\">";

                _children.each(function(index, item) {
                    let _class = "";

                    if (!index && (_active_tab == '')) {
                        _class += "active";
                        window.history.pushState("", $(item).data('tab-name'), window.location.href + '/' + $(item).attr('id'));
                    } else {
                        if (_active_tab == $(item).attr('id')) {
                            _class += "active";
                        }
                    }
                    _nav += "<a href=\"" + $(item).attr('id') + "\" class=\"tab-nav-item " + _class + "\">" + $(item).data('tab-name') + "</a>";
                    if ($(_children[index + 1]).length) {
                        $(item).append("<div class=\"col text-center pt-2 pb-2\"><button data-tab=\"" + $(_children[index + 1]).attr('id') + "\" class=\"btn btn-dark ra-next-tab btn-icon m-auto text-uppercase\">" + $(_children[index + 1]).data('tab-name') + "<span class=\"iconify\" data-icon=\"ci:chevron-right\"></span></button></div>");
                    }





                });
                _nav += "</nav>";
                let _grandparent = _parent.siblings('.post-nav-bar');
                _grandparent.html(_nav);


                $('.ra-next-tab').on('click', function(e) {
                    e.preventDefault();
                    $('a[href="' + $(this).data('tab') + '"]').click();
                });


                _grandparent.find('a').on('click', function(e) {
                    e.preventDefault();
                    let _id = $(this).closest('[target-id]').attr('target-id');
                    _this.find(".tab-item").removeClass('open');
                    $(this).siblings('a').removeClass('active');
                    $(this).addClass('active');
                    let _location = null;

                    if ($(this).parent().parent().parent().prev().prev().hasClass('c-thumbs-gallery')) {
                        _location = $(this).parent().parent().parent().prev().prev();
                    } else {
                        _location = $(this).parent().parent().next();
                    }
                    $('html, body').animate({
                        scrollTop: _location.offset().top - 80
                    }, 0);

                    if (!$("#" + $(this).attr("href")).hasClass('open')) {
                        $("#" + $(this).attr("href")).addClass('open');
                        let parsedUrl = window.location.href.replace(/\/$/, "");
                        parsedUrl = window.location.href.substring(0, parsedUrl.lastIndexOf('/'));


                        window.history.pushState({}, '', parsedUrl + '/' + $(this).attr('href')) + '/';
                        //Go to slide
                        swiperthumbs.slideTo($("[data-tab-anchor=\"" + $(this).attr("href") + "\"]").index());
                        swiper2.slideTo($("[data-tab-anchor=\"" + $(this).attr("href") + "\"]").index());

                    }
                });


                $(_parent.parent().find('.post-nav-bar')[0]).appendTo($(_parent.parent().find('.tab-wrapper')[0]));
                $(_parent.parent().find('.post-body ')[0]).appendTo($(_parent.parent().find('.tab-wrapper')[0]));


                _index++;
                $(this).addClass('loaded');


            });

        }

        //Build gallery
        $(".tabs-block .tab-item").each(function(index, item) {
            let _active_tab = $($(item).parents("[data-active-tab]")[0]).attr('data-active-tab');

            if (!index && _active_tab == '') {
                $(item).addClass('open');
            } else {
                if (_active_tab == $(item).attr('id')) {
                    $(item).addClass('open');
                }
            }
            let _tab_name = $(item).attr('id');

            $(this).find(".img-container img").each(function(index, item) {
                let attr = "";
                if (!index) {
                    attr += " data-tab-anchor=\"" + _tab_name + "\" ";
                }
                $("<div class=\"swiper-slide\"" + attr + ">" + item.outerHTML + "</div>").appendTo(".c-thumbs-gallery__thumbs .swiper-wrapper, .c-thumbs-gallery__full .swiper-wrapper");
            });
        });

        const swiperthumbs = new Swiper(".c-thumbs-gallery__thumbs", {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
        });

        const swiper2 = new Swiper(".c-thumbs-gallery__full", {
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            loop: true,
            thumbs: {
                swiper: swiperthumbs,
            },
        });
    }

}

const tabModule = new TabModule();