class NotificationsModule {
    constructor() {
        this.notificationsMainWrapper = $('.s-notifications-drawer');
        this.notificationsList = $('.s-notifications-drawer .notifications-list');
        this.clearAllButton = $('#login-mobile-drawer .clear-all-notifications');

        this.currentPage = 0;
        this.loadMore = true;
        this.loading = false;
        this.loadNotifications(this);
        this.events();

    }

    // Events
    events() {
        this.clearAllButton.on('click', this.clearAll.bind(this));
        this.notificationsMainWrapper.on('scroll', this.loadMoreNotifications.bind(this));

    }

    loadMoreNotifications() {

        if (!this.loadMore) {
            return;
        }
        const elem = this.notificationsMainWrapper;
        if (elem[0].scrollHeight - elem.scrollTop() >= elem.outerHeight() - 20) {
            this.loadNotifications(this);
        }

    }

    renderNotification(url, title, author, date, seen) {
        return `
        <article class="c-article-card c-article-card--horizontal c-article-card--notification  fade-in-right">
            <div class="p-0 ${seen?'seen':''}">
                <h3 class="c-article-card__title mb-1"><a href="${url}">${title}</a></h3>
                <div class="c-article-card__footer">
                    <span class="c-article-card__date">
                        ${author}
                    </span>
                    <div class="c-article-card__date">
                        ${date}
                    </div>
                </div>
            </div>
        </article>
        `;
    }

    clearAll() {
        let _this = this;
        this.notificationsList.empty();
        //  this.notificationsList.append('<p class="user-message">Não tem notificações disponíveis.</p>');
        this.loading = true;
        this.notificationsList.addClass('loading');

        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/notifications/clear/",
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            }
        }).done(function(results) {

            _this.hasmore = false;
            _this.loadMore = false;
            _this.currentPage = 0;
            if (results.success) {
                _this.notificationsList.append('<p class="user-message">' + results.success + '</p>');
            }
            _this.notificationsList.removeClass('loading');
            _this.notificationsList.addClass('clean');
            _this.loading = false;


        });
        // this.notificationsList.append('<p class="user-message">Não tem notificações disponíveis.</p>');
    }

    loadNotifications(t) {
        let _this = t;
        if (_this.loading) {
            return;
        }

        _this.notificationsList.addClass('loading');

        _this.loading = true;
        $.ajax({
            method: "GET",
            url: ra_theme.site_url + "/wp-json/ra/v1/notifications/get/" + this.currentPage,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            }
        }).done(function(results) {
            _this.loadMore = results.hasmore;
            _this.currentPage++;
            _this.notificationsList.removeClass('loading');
            if (results.errors) {
                _this.loading = false;
                return;
            }

            if (results.posts.length) {
                _this.notificationsList.removeClass('clean');
            } else {
                _this.notificationsList.addClass('clean');
            }
            results.posts.forEach((el, i) => {
                _this.notificationsList.append(_this.renderNotification(el.url, el.title, el.author, el.date, el.seen));
            });
            _this.loading = false;

        });
    }
}

const notificationsModule = new NotificationsModule();