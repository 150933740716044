class LazyLoad {

    constructor() {

        this.images = Array.from(document.querySelectorAll('article [loading="lazy"]:not(.loaded)'));
        console.log(this.images);

        this.imageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    window.setTimeout(function() {
                        /* if (image.dataset.src && !image.classList.contains('loaded')) {

                             image.src = image.dataset.src;

                         }*/

                        image.classList.add('loaded');
                        observer.unobserve(image);
                    }, 250);
                }
            });
        });

        this.events();
    }

    fetchImages() {
        this.images = Array.from(document.querySelectorAll('article [data-src][loading="lazy"]:not(.loaded), article [loading="lazy"]:not(.loaded)'));
        this.events();

    }


    // Events

    events() {
        if ('IntersectionObserver' in window) {
            this.images.forEach(img => this.imageObserver.observe(img));
        } else {
            this.images.forEach(img => {
                /*  if (image.dataset.src) {
                      img.src = img.dataset.src;
                  }*/
                img.classList.add('loaded');
            });
        }

    }


}

const ra_lazyload = new LazyLoad();