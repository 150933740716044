$('#social-share-mobile-drawer').on('show.bs.modal', function(event) {
    var modal = $(this);



    var button = $(event.relatedTarget) // Button that triggered the modal
    var title = button.data('share-title') // Extract info from data-* attributes
    var link = window.location
    var img = button.data('share-imgurl')

    modal.find('.modal-title').text(title)

    // #TODO construct the social links
    modal.find('.s-fb').attr("href", `https://www.facebook.com/sharer/sharer.php?u=${link}`);
    modal.find('.s-whatsapp').attr("href", `https://api.whatsapp.com/send?text=${link}`).data("action", "share/whatsapp/share");
    modal.find('.s-twitter').attr("href", `https://api.whatsapp.com/send?text=${link}`).data("action", "share/whatsapp/share");

    modal.find('.s-link').attr("data-clipboard", link);
});

$(document).on('click', '.js-share-btn--mobile', function() {
    let _title = $(this).data('title');
    if (navigator.share) {

        navigator.share({
                title: "Razão Automóvel",
                text: _title,
                url: window.location,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));


        return;
    } else {
        $('#social-share-mobile-drawer').modal('show');
    }

});