// Define functionality inside function to prevent global variable polution
function mainMenu() {
    const openMobileBtn = $(".js-open-mobile-menu");
    const closeMobileBtn = $(".js-close-mobile-menu");

    const openSearchBtn = $(".js-open-search-overlay");
    const closeSearchBtn = $(".js-close-search-overlay");

    const mobileMenu = $(".p-main-mobile-menu");
    const searchOverlay = $(".p-main-search-overlay");

    const searchButton = $(".p-main-search-overlay__input-group .btn");

    const body = $("body");

    openMobileBtn.on("click", openMobileMenu);
    closeMobileBtn.on("click", closeMobileMenu);

    openSearchBtn.on("click", openSearchOverlay);
    closeSearchBtn.on("click", closeSearchOverlay);

    searchButton.on("mouseup", performSearch);

    $(".p-megamenu .dropdown-toggle__label").on('click', function() {
        window.location = $($(this).parent()[0]).attr("href");
    });

    function performSearch() {
        if ($(".js-search-overlay-input").val() != "") {
            window.location.href = ra_theme.site_url + '?s=' + $(".js-search-overlay-input").val();
        }
    }

    function openMobileMenu() {
        mobileMenu.addClass("is-open");
        body.addClass("no-scroll");
    }

    function closeMobileMenu() {
        mobileMenu.removeClass("is-open");
        body.removeClass("no-scroll");
    }

    function openSearchOverlay() {
        searchOverlay.addClass("is-open");
        body.addClass("no-scroll");
    }

    function closeSearchOverlay() {
        searchOverlay.removeClass("is-open");
        body.removeClass("no-scroll");
    }

    // Megamenu horizontal tabs
    $(".js-megamenu-horizontal-tabs-link").on("mouseenter", (event) => {
        const currentLink = event.target;
        const tabGroup = currentLink.closest(".p-megamenu-horizontal-tabs");
        const associatedPanelName = $(currentLink).data("targetpanel");
        const associatedPanel = $(`.tab-pane[data-panel="${associatedPanelName}"]`);

        // only remove active class from .tab-pane inside this tabGroup
        $(tabGroup).find(".tab-pane").removeClass("active");
        $(associatedPanel).addClass("active");

        $(".js-megamenu-horizontal-tabs-link.active").removeClass('active');
        $(currentLink).addClass("active");
    });

    // Search Overlay
    function delay(callback, ms) {
        var timer = 0;
        return function() {
            var context = this,
                args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function() {
                callback.apply(context, args);
            }, ms || 0);
        };
    }

    var xhr;

    $(document).mouseup(function(e) {
        var container = $(".p-main-search-overlay__sugestions");

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.removeClass('is-visible');
        }
    });

    // the event will only fire after user stops typing for 500ms due to delay function
    $(".p-main-search-overlay__inner").on(
            "keyup",
            "input.js-search-overlay-input",
            delay(function(e) {
                    const searchString = $(this).val().toLocaleLowerCase();

                    if (e.key === "Enter") {
                        // console.log('enter key pressed');
                        //  performSearch();
                        return;
                    }


                    // if user typed more than 3 chars make request to WS
                    if (searchString.length >= 3) {
                        $(".p-main-search-overlay__sugestions").addClass("is-visible");
                        $(".p-main-search-overlay__sugestions").html(`<div class="p-main-search-overlay__sugestions-item">
                      <p class="p-main-search-overlay__sugestions-info  text-center justify-content-center d-flex align-items-center mb-0">
                          <span class="loader small mr-2"></span>A procurar resultados...</p>
                    </div>`)
                        if (xhr && xhr.state() == "pending") {
                            xhr.fail();
                        }

                        xhr = $.ajax({
                                method: "POST",
                                url: ra_theme.site_url + "/wp-json/ra/v1/search",
                                data: {
                                    s: searchString,
                                }
                            }).then((sugestions) => {
                                    if (sugestions.posts.length) {
                                        $(".p-main-search-overlay__sugestions-item").remove();
                                        // insert with template string
                                        $(".p-main-search-overlay__sugestions").html(`
                                ${sugestions.posts
                                  .map(
                                    (sugestion) => `
                                  <div class="p-main-search-overlay__sugestions-item">
                                    <p class="p-main-search-overlay__sugestions-category">${sugestion.category}</p>
                                    <a href="${sugestion.url}" class="p-main-search-overlay__sugestions-title">${sugestion.title}</a>
                                  </div>
                                `
                                  )
                                  .join(
                                    ""
                                  ) /* this join is need or else , will show after each item */
                                }
                                `);
                            } else {
                              $(".p-main-search-overlay__sugestions-item").remove();
                              // show no results text
                              $(".p-main-search-overlay__sugestions").html(`
                                <div class="p-main-search-overlay__sugestions-item">
                                  <p class="p-main-search-overlay__sugestions-info text-center mb-0">Sem Resultados</p>
                                </div>
                              `);
                            }

                          });
      } else {
        // not enough characters! Will remove sugestions and hide panel
        $(".p-main-search-overlay__sugestions-item").remove();
        $(".p-main-search-overlay__sugestions").removeClass("is-visible");
      }
    },500)
  );
}

mainMenu();