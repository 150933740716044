class QuizModule {
    constructor() {

        this.initQuiz();
    }

    // Events

    initQuiz() {
        $('.quiz:not(.initialized)').each(function(index, el) {
            let _this = $(el);
            _this.addClass('initialized');
            _this.find('button').click(function(index, el) {
                var isCorrect = $(this).data('correct');
                if (isCorrect) {
                    $(_this.find('.js-question')).fadeOut('fast', function() {
                        $(_this.find('.js-answer-ok')).fadeIn('fast');

                        _this.addClass('quiz-correct');
                    });
                } else {
                    $(_this.find('.js-question')).fadeOut('fast', function() {
                        $(_this.find('.js-answer-ko')).fadeIn('fast');

                        _this.addClass('quiz-incorrect');
                    });
                }
            });

        });
    }
}

const quizModule = new QuizModule();