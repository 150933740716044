const verticalSlider = new Swiper(".c-vertical-slider", {
  slidesPerView: 4,

  // Optional parameters
  navigation: {
    nextEl: ".c-vertical-slider__button-next",
    prevEl: ".c-vertical-slider__button-prev",
  },

  breakpoints: {
      768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          direction: "vertical",
          // Optional parameters
          navigation: {
            nextEl: ".c-vertical-slider__button-next",
            prevEl: ".c-vertical-slider__button-prev",
          },
      },
  },
});
