class onBoarding {
    constructor() {
        this.modal = $('#onboarding');



        this.initOnboarding(this);
    }

    // Events

    initOnboarding(t) {

        if (t.modal.length) {
            $('#onboarding').modal('show');

            $('#onboarding .s-btn').on('click', (e) => {
                e.preventDefault();
                $('#onboarding').modal('hide');

            });


            window.setTimeout(function() {


                $('#onboarding .s-logo').addClass('show');
                window.setTimeout(function() {
                    $('#onboarding .s-disclamer').addClass('show');
                    window.setTimeout(function() {
                        $('#onboarding .s-disclamer .s-welcome').addClass('show');

                        window.setTimeout(function() {
                            $('#onboarding .s-btn').addClass('show');
                        }, 500);
                    }, 1500)
                }, 1000);
            }, 1000);
        }
    }
}

const onBoardingModule = new onBoarding();