class UserMenu {
    constructor() {
        this.modal = $("#login-mobile-drawer");
        this.events();

    }

    events() {
        this.modal.on('show.bs.modal', this.triggerModal.bind(this));
    }

    triggerModal(event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        $('.modal').modal('hide');
        $('.p-main-mobile-menu').removeClass('is-open');
    }



}

const userMenu = new UserMenu();