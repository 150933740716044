class AuthForm {
    constructor() {
        this.container = $(".c-authform");
        this.registerLink = $(".js-register-link");
        this.loginLink = $(".js-login-link");
        this.recoverLink = $(".s-recover-pass");
        this.seePwd = $(".see-pwd-button");


        this.loginForm = $("#loginform");
        this.loginInput = $("#loginform input");
        this.registerForm = $("#registerform");
        this.registerFormInput = $("#registerform input");

        this.lostPasswordForm = $("#recoverform");

        this.logoutButton = $('#user-logout-button');

        this.loader = `<span class="loader">
        <span class="left"><span class="anim"></span></span>
        <span class="right"><span class="anim"></span></span>
      </span>`;


        this.events();
    }

    events() {
        this.registerLink.on('click', this.showRegisterForm.bind(this));
        this.loginLink.on('click', this.showLoginForm.bind(this));
        this.recoverLink.on('click', this.showRecoverForm.bind(this));

        this.loginForm.on('submit', this.loginFormSubmission.bind(this));
        this.loginInput.on('keydown', this.clearErrors.bind(this));

        this.registerForm.on('submit', this.signupFormSubmission.bind(this));
        this.registerFormInput.on('keydown', this.clearErrors.bind(this));

        this.lostPasswordForm.on('submit', this.lostFormSubmission.bind(this));

        this.logoutButton.on('mousedown', this.logoutCurrentUser.bind(this));
        this.seePwd.on('click', function(e) {
            e.preventDefault();
            let _pwdInput = $(this).prev('input');
            if (_pwdInput.attr('type') == 'password') {
                return _pwdInput.attr('type', 'text');
            }

            _pwdInput.attr('type', 'password');
        });

    }

    clearErrors(e) {
        $(e.currentTarget).removeClass('is-invalid');
    }

    showPwd(e) {
        e.target;

    }

    logoutCurrentUser() {
        this.logoutButton.html('A fechar sessão');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ra_theme.site_url + "/wp-json/ra/v1/auth/logout/",
            data: {
                'ref': document.location.href,
            },
            beforeSend: function(xhr) {
                xhr.setRequestHeader('X-WP-Nonce', ra_theme.nonce);
            }
        }).done(function(data) {
            if (data.loggedout == true) {
                document.location.reload(data.ref);

            }
            this.logoutButton.html('Logout');

        });
    }

    showRegisterForm() {
        $('form#registerform #wp-submit').removeClass('fade').removeClass('d-none');
        $('form#registerform #username').removeClass('fade').removeClass('d-none');
        $('form#registerform p.s-info').removeClass('fade-in-right').addClass('d-none').html("");

        $('form#registerform .form-group, form#registerform .login-submit').removeClass('fade').removeClass('d-none');


        this.container.addClass('is-register').removeClass('is-recover');

    }

    showLoginForm() {
        this.container.removeClass('is-register').removeClass('is-recover');
    }

    showRecoverForm() {
        $('form#recoverform #wp-submit').removeClass('fade').removeClass('d-none');
        $('form#recoverform #username').removeClass('fade').removeClass('d-none');
        $('form#recoverform p.s-info').removeClass('fade-in-right').addClass('d-none').html("");

        this.container.addClass('is-recover').removeClass('is-register');

    }

    loginFormSubmission(e) {
        e.preventDefault();

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ra_theme.site_url + "/wp-json/ra/v1/auth/login/",
            data: {
                'username': $('form#loginform #username').val(),
                'password': $('form#loginform #password').val(),
                'remember': $('form#loginform #rememberme').is(':checked'),
                'ref': document.location.href,
                'security': $('form#loginform #security').val()
            }
        }).done(function(data) {
            $('form#loginform input.is-invalid').removeClass('is-invalid');
            $('form#loginform input+p.invalid-feedback').remove();

            if (data.errors) {
                if (data.errors.incorrect_password) {
                    $('form#loginform #password').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.incorrect_password[0] + '</p>');
                }
                if (data.errors.empty_password) {
                    $('form#loginform #password').addClass('is-invalid').after('<p class="invalid-feedback fade-in-right">' + data.errors.empty_password[0] + '</p>');
                }

                if (data.errors.invalid_email) {
                    $('form#loginform #username').addClass('is-invalid').after('<p class="invalid-feedback fade-in-right">' + data.errors.invalid_email[0] + '</p>');
                }
                if (data.errors.empty_email) {
                    $('form#loginform #username').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.empty_email[0] + '</p>');
                }

                if (data.errors.invalid_username) {
                    $('form#loginform #username').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_username[0] + '</p>');
                }
                if (data.errors.empty_username) {
                    $('form#loginform #username').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.empty_username[0] + '</p>');
                }
                if (data.errors.invalid_form) {
                    $('form#loginform #username').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_form[0] + '</p>');

                }
                return;
            }

            if (data.loggedin == true) {
                document.location.reload();
            }


        });
    }

    signupFormSubmission(e) {
        e.preventDefault();
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ra_theme.site_url + "/wp-json/ra/v1/auth/register/",
            data: {
                'firstname': $('form#registerform #register_first_name').val(),
                'lastname': $('form#registerform #register_last_name').val(),
                'email': $('form#registerform #register_email').val(),
                'password': $('form#registerform #register_pass').val(),
                'ref': document.location.href,
                'security': $('form#registerform #security').val(),
                'optin': $('form#registerform #register_terms').is(':checked'),
                'captcha': grecaptcha.getResponse()
            }
        }).done(function(data) {

            $('form#registerform p.invalid-feedback ').remove();

            if (data.errors) {

                if (data.errors.invalid_firstname) {
                    $('form#registerform #register_first_name').addClass('is-invalid').after('<p class="invalid-feedback fade-in-right">' + data.errors.invalid_firstname[0] + '</p>');
                }

                if (data.errors.invalid_lastname) {
                    $('form#registerform  #register_last_name').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_lastname[0] + '</p>');
                }
                if (data.errors.invalid_email) {
                    $('form#registerform #register_email').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_email[0] + '</p>');
                }

                if (data.errors.invalid_password) {
                    $('form#registerform #register_pass').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_password[0] + '</p>');
                }
                if (data.errors.invalid_optin) {
                    $('form#registerform #register_terms+.custom-control-label').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_optin[0] + '</p>');
                }

                if (data.errors.invalid_form) {
                    $('form#registerform #register_pass').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_form[0] + '</p>');
                }

                if (data.errors.invalid_captcha) {
                    $('form#registerform #captcha').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_captcha[0] + '</p>');
                }
                grecaptcha.reset();
                return;
            }


            if (data.email) {
                $('form#registerform p.s-info').addClass('fade-in-right').removeClass('d-none').html(data.message);
                $('form#registerform .form-group, form#registerform .login-submit').addClass('fade').addClass('d-none');
            }


        });
    }

    lostFormSubmission(e) {
        e.preventDefault();

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ra_theme.site_url + "/wp-json/ra/v1/auth/lost/",
            data: {
                'username': $('form#recoverform #username').val(),
                'ref': document.location.href,
                'security': $('form#recoverform #security').val()
            }
        }).done(function(data) {
            $('form#recoverform p.invalid-feedback').remove();

            if (data.errors) {
                if (data.errors.invalid_username) {
                    $('form#recoverform #username').addClass('is-invalid').after('<p class="invalid-feedback  fade-in-right">' + data.errors.invalid_username[0] + '</p>');
                }
                return;
            }

            if (data.success) {
                $('form#recoverform #wp-submit').addClass('fade').addClass('d-none');
                $('form#recoverform #username').addClass('fade').addClass('d-none');
                $('form#recoverform p.s-info').addClass('fade-in-right').removeClass('d-none').html(data.success);
            }


        });
    }


}

const authform = new AuthForm();